





















































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import DoctorChamberService from "../../service/DoctorChamberService";
import { IDoctorChamber } from "../../models/DoctorChamber";
import { IDivision } from "../../../common-app/models/Division";
import { IDistrict } from "../../../common-app/models/District";
import { IThana } from "../../../common-app/models/Thana";
import UniversalService from "../../../common-app/service/UniversalService";
import Create from "./Create.vue";
import AlertService from "../../../common-app/service/AlertService";
import { Validator } from "vee-validate";
@Component({
  components: {
    Create,
  },
})
export default class ChamberSettings extends Vue {
  public chambers: IDoctorChamber[] = this.storeChambers;
  public chamber: IDoctorChamber = {} as IDoctorChamber;
  public divisions: IDivision[] = [];
  public districts: IDistrict[] = [];
  public thanas: IThana[] = [];
  public selectedThanas: IThana[] = [];
  public submited: boolean = false;
  public currentIndex: number = 0;
  public showVirtualConfirm:boolean=false;
  public get storeChambers(): IDoctorChamber[] {
    this.$store.watch(
      (state) => {
        return this.$store.getters.chamberList;
      },
      (val) => {
        //something changed do something
        return val || [];
      },
      {
        deep: true,
      }
    );
    return this.$store.getters.chamberList || [];
  }
  created() {
      const dict = {
      custom: {
        Name: {
          required: "Chamber name is required.",
        },
        Viber: {
          required: "Viber number is required.",
          numeric: "The viber number can only contain numeric characters.",
          min: "The viber number must be at least 11 characters.",
        },
        Whatsapp: {
          required: "Whatsapp number is required.",
          numeric: "The Whatsapp number can only contain numeric characters.",
          min: "The Whatsapp number must be at least 11 characters.",
        },
      },
    };
    this.$validator.localize("en", dict);


    UniversalService.GetAddress().then((res) => {
      this.divisions = res.data.divisions;
      this.districts = res.data.districts;
      this.thanas = res.data.thanas;
    });
    DoctorChamberService.GetAllChamberByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result || [];
        //console.log(this.chambers);
      }
    });
  }
  chamberFrom() {
    
    this.$validator.validateAll().then((valid) => {
      if (valid) {
        this.submited = true;
        //console.log(this.chamber);
        DoctorChamberService.update(this.chamber)
          .then((res) => {
            AlertService.sendMessage({ status: true, message: "success" });
            this.$store.dispatch("UpdateChamberList", res.data.result);
            this.chamber = {} as IDoctorChamber;
            this.chambers[this.currentIndex] = res.data.result;
            this.$bvModal.hide("edit-chamber");
            this.submited = false;
          })
          .catch((err) => {
            //alert("faild");
            AlertService.sendMessage({ status: false, message: "failed" });
            this.submited = false;
          });
      }
    });
  }

  chamberFromVirtual() {
        if(this.chamber.isPreferredWhatsapp && this.chamber.isPreferredViber || this.chamber.isPreferredWhatsapp && this.chamber.isPreferredOthers || this.chamber.isPreferredViber&& this.chamber.isPreferredOthers){
      AlertService.sendMessage({ status: false, message: "please select one Preferred" });
      return;
    }
    // if(this.chamber.whatsapp.length<1 && this.chamber.isPreferredWhatsapp || this.chamber.whatsapp.length>0 && !this.chamber.isPreferredWhatsapp)
    //  {
    //   AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
    //   return;
    //  }
    //  else if(this.chamber.viber.length<1 && this.chamber.isPreferredViber || this.chamber.viber.length>0 && !this.chamber.isPreferredViber){
    //      AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
    //      return;
    //  }

   if(this.chamber.whatsapp.length>0 && this.chamber.isPreferredWhatsapp)
     {
      this.showVirtualConfirm=true;
     }
     else if(this.chamber.viber.length>0 && this.chamber.isPreferredViber){
        this.showVirtualConfirm=true;
     }
     else if(this.chamber.others.length>0 && this.chamber.isPreferredOthers){
       this.showVirtualConfirm=true;
     }
     else{
       AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
     }
     if(!this.showVirtualConfirm)
     {
      AlertService.sendMessage({ status: false, message: "please select one field & Preferred" });
      return;
     }

    this.$validator.validateAll().then((valid) => {
      valid=true;
      if (valid) {
        this.submited = true;
        DoctorChamberService.update(this.chamber)
          .then((res) => {
            AlertService.sendMessage({ status: true, message: "success" });
            this.$store.dispatch("UpdateChamberList", res.data.result);
            this.chamber = {} as IDoctorChamber;
            this.chambers[this.currentIndex] = res.data.result;
            this.$bvModal.hide("edit-virtual-chamber");
            this.submited = false;
            AlertService.sendMessage({ status: true, message: "success" });
          })
          .catch((err) => {
            //alert("faild");
            AlertService.sendMessage({ status: false, message: "failed" });
            this.submited = false;
          });
      }
    });
  }

  onChange(event: any) {
    this.selectedThanas = this.thanas.filter((s) => s.districtId === Number(event.target.value));
  }
  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorChamberService.delete(id)
            .then((res) => {
              this.$store.dispatch("RemoveChamber", index);
              this.chambers.splice(index, 1);
              // alert("success deleted");
              AlertService.sendMessage({ status: true, message: "success deleted" });
              this.chambers = this.chambers.filter((s) => s.id != id) || [];
            })
            .catch((err) => AlertService.sendMessage({ status: false, message: "failed" }));
        }
      })
      .catch((err) => {
        // An error occurred
      });
    // window.location.reload();
  }
  openModal(chamber: IDoctorChamber, index: number) {
    this.currentIndex = index;
    this.selectedThanas = this.thanas.filter((s) => s.districtId === chamber.districtId);
    this.chamber = Object.assign({}, chamber);
    this.$bvModal.show("edit-chamber");
  }
  openVtChamberEditModal(chamber: IDoctorChamber, index: number) {
    this.currentIndex = index;
    this.selectedThanas = this.thanas.filter((s) => s.districtId === chamber.districtId);
    this.chamber = Object.assign({}, chamber);
    // console.log(this.chamber);
    this.$bvModal.show("edit-virtual-chamber");
  }
}
